import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Form, Row, Col, Button, FormLabel, Modal, Container, TextBox, InputGroup } from 'react-bootstrap';
import Picklist from './Picklist'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import { Layout } from "../Layout";
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';

import PatientSearchService from '../../services/patientSearchService';
import ItnAuthService from '../../services/itnAuthService';
import AuthService from '../../services/authService';
import { TruckFlatbed } from 'react-bootstrap-icons';
import ErrorDisplay from '../../services/errorDisplay';

const ItnAuthList = (props) => {
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [cancelToken, setCancelToken] = useState();
    
    const [sortField, setSortField] = useState('itnNumber');
    const [sortDir, setSortDir] = useState('asc');
    const [numPerPage, setNumPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [showList, setShowList] = useState(false);
    const [allClients, setAllClients] = useState(true);
    const [clientList, setClientList] = useState();
    
    const [clientCodeList, setClientCodeList] = useState([]);
    const [selectedClientList, setSelectedClientList] = useState([]);

    const [showClientDropdown, setShowClientDropdown] = useState(false);
    const [showClientPicker, setShowClientPicker] = useState(false);
    const [clientsForDropdown, setClientsForDropdown] = useState([]);

    const [searchMsg, setSearchMsg] = useState('');
    const [noResultsMsg, setNoResultsMsg] = useState('No results');
    const [showNoRusults, setShowNoResults] = useState(false);
    
    const [showClientError, setShowClientError] = useState(false);
    const [clientError, setClientError] = useState("");

    const [rowToEdit, setRowToEdit] = useState();
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");

    const [denyList, setDenyList] = useState([]);
    const [infoList, setInfoList] = useState([]);
    const [selectedAction, setSelectedAction] = useState('N');

    const [patientResponsibilityValue, setPatientResponsibilityValue] = useState(0);
    const [maximumCoveredValue, setMaximumCoveredValue] = useState(0);
    const [denialCodeValue, setDenialCodeValue] = useState('');
    const [additionalInfoValue, setAdditionalInfoValue] = useState('');
    const [otherSpecialHandlingValue, setOtherSpecialHandlingValue] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);


    useEffect(() => {
        PatientSearchService.getAvailableClients((data) => {
            setClientList(data);
            setClientCodeList(Object.keys(data));

            var keys = Object.keys(data);
            var dropdownClients = [];
            
            for (var i = 0; i < keys.length; i++) {
                var item = {};
                item.key = keys[i];
                item.value = data[item.key];
                dropdownClients.push(item);
            }

            setClientsForDropdown(dropdownClients);

            if (keys.length === 1) {
                setShowClientDropdown(true);
                setShowClientPicker(false);
                setAllClients(true);
            } else {
                setShowClientDropdown(false);
                setShowClientPicker(true);
            }
        });
        
        ItnAuthService.getDenyList(function (data) {
            setDenyList(data);
        });

        ItnAuthService.getInfoList(function (data) {
            setInfoList(data);
        });

    }, []);

    useEffect(() => {
        resetModal(true);

        if (rowToEdit?.itnNumber) {
            AuthService.getAuthToken(false, (token) => {
                setPdfUrl("/api/itn/pdf/" + rowToEdit.itnNumber + "?token=" + token);
            });
        }
    }, [rowToEdit]);

    useEffect(() => {
        console.log(sortField);
    }, [sortField]);

    useEffect(() => {
        resetModal(false);

        if (selectedAction === "D") {
            setDenialCodeValue("0188");
        }

        if (selectedAction === "I") {
            setAdditionalInfoValue("0507");
        }

        if (selectedAction === "N") {
            setSaveButtonDisabled(true);
        } else {
            setSaveButtonDisabled(false);
        }

    }, [selectedAction]);

    const onSelectedClientsChange = (clients) => {
        setSelectedClientList(clients);
    }

    const columns = [
        {
            dataField: 'client',
            text: 'Client',
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'policy',
            text: 'Policy #',
            headerStyle: {
                textAlign: 'center',
                width: '125px'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'incidentNumber',
            text: 'Incident #',
            headerStyle: {
                textAlign: 'center',
                width: '125px'
            },
            style: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'patientName',
            text: 'Patient Name',
            headerStyle: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'itnNumber',
            text: 'ITN',
            headerStyle: {
                textAlign: 'center',
                width: '110px'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'providerName',
            text: 'Provider',
            headerStyle: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'datesOfService',
            text: 'Dates of Service',
            headerStyle: {
                textAlign: 'center'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'totalChargeFormatted',
            text: 'Billed Amount',
            headerStyle: {
                textAlign: 'center',
                width: '100px'
            },
            sort: true,
            style: {
                textAlign: 'right'
            },
            filter: textFilter(),
        },
        {
            dataField: 'currency',
            text: 'Billed Currency',
            headerStyle: {
                textAlign: 'center',
                width: '100px'
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'hasBills',
            text: 'Details',
            headerStyle: {
                textAlign: 'center',
                width: '50px'
            },
            style: {
                textAlign: 'center'
            },
            formatter: billsFormatter
        }
    ];

    function billsFormatter(cell, row) {
       
        return (
            <Button className="btn btn-md u-btn-primary" onClick={() => openDetailsDialog(row)}>View</Button>
        );
     
    }

    const openDetailsDialog = (row) => {
        setRowToEdit(row);
        setShowModal(true);
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total m-1">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const selectRow = {
        mode: 'checkbox',
        hideSelectColumn: true,
    };

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <span className="react-bs-table-sizePerPage-dropdown dropdown">
            <button className="dropdown-toggle btn btn-outline-dark" data-toggle="dropdown" id="dropdownMenuButton" role="group">
                {currSizePerPage + ' per page'}
            </button>
            <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map(option => (
                        <li key={option.text} onClick={() => { onSizePerPageChange(option.page); setNumPerPage(option.page) }}
                            className={'dropdown-item'} role='presentation'>
                            {option.text}
                        </li>
                    ))
                }
            </ul>
        </span>
    );

    const paginationOptions = {
        custom: true,
        showTotal: true,
        totalSize: tableData.length,
        sizePerPageList: [10, 25, 50],
        sizePerPage: numPerPage,
        nextPageText: 'Next',
        prePageText: 'Previous',
        sizePerPageRenderer,
        paginationTotalRenderer: customTotal
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (clientCodeList.length === 0) {
            ErrorDisplay.renderError('Client list is not yet initialized and is required for search. Please try again in a moment.');
            return;
        }

        setShowList(false);
        setShowNoResults(false);
        setIsLoading(true);
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        var postData = {
            clients: allClients ? clientCodeList : selectedClientList
        };

        getAuthorizationData(postData);
    }

    const getAuthorizationData = (request) => {
        if (cancelToken) {
            cancelToken.cancel("Current activity search reset");
        }

        var instanceToken = axios.CancelToken.source();
        setCancelToken(instanceToken);
        
        ItnAuthService.getAuthorizationData(request, instanceToken, function (authData) {
            setData(authData);
            setTableData(authData);

            if (authData.length === 0) {
                setShowNoResults(true);
            } else {
                setShowList(true);
            }
            
            setIsLoading(false);
        });

        if (denyList.length === 0) {
            ItnAuthService.getDenyList(function (data) {
                setDenyList(data);
            });
        }

        if (infoList.length === 0) {    
            ItnAuthService.getInfoList(function (data) {
                setInfoList(data);
            });
        }
    }

    const resetForm = (e) => {
        e.preventDefault();

        if (cancelToken) {
            cancelToken.cancel("Authorization search reset");
        }

        ErrorDisplay.clear();
        setIsLoading(false);
        setAllClients(true);
        setData([]);
        setTableData([]);
        setShowList(false);
        setShowNoResults(false);
    }

    const handleCancel = (e) => {
        e.preventDefault();

        resetModal(true);
        setShowModal(false);
    }

    const handleSave = (e) => {
        e.preventDefault();

        saveAuthorization(false);

    }

    const handlePrevious = (e) => {
        var next = true;
        var rowToDelete = 0
        var itnToDelete = "";
        for (var i = 0; i < tableData.length; i++) {
            if (tableData[i].itnNumber === rowToEdit.itnNumber) {
                rowToDelete = i;
                
                if (i === tableData.length - 1) {
                    next = false;
                }
                                     
            }
        }


       
        if (rowToDelete > 0 && next) {
            resetModal(true);
            setRowToEdit(tableData[rowToDelete - 1]);
        } else {
            setShowModal(false);
        }
    }

    const handleNext = (e) => {
        e.preventDefault();

        var next = true;
        var rowToDelete = 0
        var itnToDelete = "";
        for (var i = 0; i < tableData.length; i++) {
            if (tableData[i].itnNumber === rowToEdit.itnNumber) {
                rowToDelete = i;
                
                if (i === tableData.length - 1) {
                    next = false;
                }
                                     
            }
        }

       
        if (tableData.length > rowToDelete && next) {
            resetModal(true);
            setRowToEdit(tableData[rowToDelete + 1]);
        } else {
            setShowModal(false);
        }

    }

    const handleSaveAndNext = (e) => {
        e.preventDefault();

        saveAuthorization(true);
    }

    const resetModal = (resetAction) => {
        if (resetAction) {
            setSelectedAction("N");
        }

        setPatientResponsibilityValue(0);
        setMaximumCoveredValue(0);
        setDenialCodeValue("0");
        setAdditionalInfoValue("0");
        setOtherSpecialHandlingValue("");
        setShowClientError(false);
        setClientError("");
        setSaveButtonDisabled(true);
    }
    
    const isAuthorizationValid = () => {
        setShowClientError(false);
        setClientError("");

        var errorMessages = "";

        if (selectedAction === "A") {

            var billedAmount = Number(rowToEdit.totalCharge);

            if (Number(patientResponsibilityValue) > billedAmount) {
                errorMessages += "Patient Responsibility cannot be greater than Total Charge. ";
            }

            if (Number(maximumCoveredValue) > billedAmount) {
                errorMessages += "Maximum Covered Amount cannot be greater than Total Charge. ";
            }

            if (Number(patientResponsibilityValue) < 0) {
                errorMessages += "Patient Responsibility cannot be less than zero. ";
            }

            if (Number(maximumCoveredValue) < 0) {
                errorMessages += "Maximum Covered Amount cannot be less than zero. ";
            }
        }

        if (selectedAction === "D") {
            if (denialCodeValue === "0") {
                errorMessages += "Reason for Denial is Required";
            }
        }

        if (selectedAction === "I") {
            if (additionalInfoValue === "0") {
                errorMessages += "Info Required is Required";
            }
        }

        if (selectedAction === "O") {
            if (otherSpecialHandlingValue.trim().length < 5 || otherSpecialHandlingValue.trim().length > 500) {
                errorMessages += "Special Handling Comment must be between 5 and 500 characters";
            }        
        }

        if (errorMessages !== "") {
            setShowClientError(true);
            setClientError(errorMessages);
            return false;
        }

        return true;
    }

    const saveAuthorization = (next) => {

        

        if (!isAuthorizationValid()) {
            return;
        }

        setIsSaving(true);

        if (cancelToken) {
            cancelToken.cancel("Save Authorization Cancelled");
        }

        var instanceToken = axios.CancelToken.source();
        setCancelToken(instanceToken);

        var request = {
            "itn": rowToEdit.itnNumber,
            "patientResponsibility": (selectedAction === 'A' ? patientResponsibilityValue : undefined),
            "patientResponsibilityCurrency": (selectedAction === 'A' ? rowToEdit.currency : undefined),
            "maximumCovered": (selectedAction === 'A' ? maximumCoveredValue : undefined),
            "maximumCoveredCurrency": (selectedAction === 'A' ? rowToEdit.currency : undefined),
            "denialCode": (selectedAction === 'D' ? denialCodeValue : undefined),
            "additionalInfo": (selectedAction === 'I' ? additionalInfoValue : undefined),
            "otherSpecialHandling": (selectedAction === 'O' ? otherSpecialHandlingValue : undefined),
        };
        
        ItnAuthService.processAuthorization(request, instanceToken, function (resp) {
            
            setIsSaving(false);
            
            if (resp.statusCode !== 200) {
                setShowClientError(true);
                setClientError(resp.message);
            } else {
                var rowToDelete = 0
                var itnToDelete = "";
                for (var i = 0; i < tableData.length; i++) {
                    if (tableData[i].itnNumber === rowToEdit.itnNumber) {
                        rowToDelete = i;
                        
                        if (i === tableData.length - 1) {
                            next = false;
                        }
                                             
                        itnToDelete = tableData[i].itnNumber;
                    }
                }

                if (!next) {
                    setShowModal(false);
                } else {
                    if (tableData.length > rowToDelete && next) {
                        resetModal(true);
                        setRowToEdit(tableData[rowToDelete + 1]);
                    }
                }

                var newTableData = tableData.filter((m, _) => {

                    if (m.itnNumber === itnToDelete) {
                        return null;
                    }
                    
                    return m;
                });

                var newData = data.filter((m, _) => {

                    if (m.itnNumber === itnToDelete) {
                        return null;
                    }
                    
                    return m;
                });


                
                setData(newData);
                setTableData(newTableData);
            }
        });

        
    }

    const onTableChange = (type, newState) => {
        console.log(type);
        console.log(newState);
        
        if (type === "filter") {
            updateDataFilter(newState.filters);
        }

        if (type === "sort") {
            setSortField(newState.sortField);
            setSortDir(newState.sortOrder);
            updateDataSort(newState.sortField, newState.sortOrder);
        }
    }

    const updateDataFilter = (filters) => {
        var filteredData = data;
        
        if (filters["client"]) {
            filteredData = filterText(filteredData, filters.client.filterVal, "client");
        }

        if (filters["policy"]) {
            filteredData = filterText(filteredData, filters.policy.filterVal, "policy");
        }

        if (filters["incidentNumber"]) {
            filteredData = filterText(filteredData, filters.incidentNumber.filterVal, "incidentNumber");
        }

        if (filters["patientName"]) {
            filteredData = filterText(filteredData, filters.patientName.filterVal, "patientName");
        }

        if (filters["itnNumber"]) {
            filteredData = filterText(filteredData, filters.itnNumber.filterVal, "itnNumber");
        }

        if (filters["providerName"]) {
            filteredData = filterText(filteredData, filters.providerName.filterVal, "providerName");
        }

        if (filters["datesOfService"]) {
            filteredData = filterText(filteredData, filters.datesOfService.filterVal, "datesOfService");
        }

        if (filters["totalChargeFormatted"]) {
            filteredData = filterText(filteredData, filters.totalChargeFormatted.filterVal, "totalChargeFormatted");
        }

        if (filters["currency"]) {
            filteredData = filterText(filteredData, filters.currency.filterVal, "currency");
        }

        setTableData(filteredData);        
    }

    const filterText = (list, filterText, field) => {
        var filteredList = [];
        for (var i = 0; i < list.length; i++) {
            var fieldVal = list[i][field].toLowerCase();
            var filterVal = filterText.toLowerCase();

            if (fieldVal.indexOf(filterVal) >= 0) {
                filteredList.push(list[i]);
            }
        }

        return filteredList;
    }

    const updateDataSort = (field, order) => {
    
        function compareAsc(a, b) {
            if ( a[field] < b[field] ){
                return -1;
            }
            
            if (a[field] > b[field]) {
                return 1;
            }

            return 0;
        }

        function compareDesc(a, b) {
            if ( a[field] < b[field] ){
                return 1;
            }
            
            if (a[field] > b[field]) {
                return -1;
            }

            return 0;
        }

        let newData = data;
        
        if (order == "asc") {
            newData.sort(compareAsc);
        } else {
            newData.sort(compareDesc);
        }

        setTableData(newData);
    }


    return (
        <Layout>
            <ReactNotification />
            <div className="g-ml-25">
                <h1>Authorization Pending</h1>
            </div>
            <div className="row justify-content-center patientSearchForm">
                <div className="col-md-6">
                    <Form onSubmit={onFormSubmit}>
                        {showClientDropdown &&
                            <Form.Group as={Row} controlId="clientDropdown">
                                <Form.Label column md="4" className="required">Client:</Form.Label>
                                <Col md="6">
                                    <Form.Control as="select">
                                        {
                                            clientsForDropdown.map((client, index) => {
                                                return (
                                                    <option key={index} value={client.key}>{client.value}</option>
                                                );
                                            })
                                        }
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                        }
                        {showClientPicker &&
                            <Form.Group as={Row} controlId="allClients">
                                <Form.Label column md="4" className="required">Clients:</Form.Label>
                                <Col md="6">
                                    <Form.Check inline label="All Clients"
                                        checked={allClients} onChange={e => setAllClients(!allClients)} />
                                </Col>
                            </Form.Group>
                        }
                        {(!showClientPicker && !showClientDropdown) &&
                            <Form.Group as={Row} controlId="noClients">
                                <Form.Label column md="4" className="required">Clients:</Form.Label>
                                <Col md="6">
                                    <b>** Clients Loading **</b>
                                </Col>
                            </Form.Group>
                        }
                        {!allClients && showClientPicker &&
                            <Form.Group as={Row} controlId="selectedClients">
                                <Picklist data={clientList} onChange={onSelectedClientsChange} initialSelected={props.searchParams ? props.searchParams.selectedClientList : []} leftLabel="Available Clients:" rightLabel="Selected Clients:" />
                                {showClientError &&
                                    <Col md="12">
                                        <span className="float-right mr-2">Please select at least one client.</span>
                                    </Col>
                                }
                            </Form.Group>
                        }
                        {(showClientPicker || showClientDropdown) &&
                            <>
                                <Button variant="primary" type="submit" className="btn btn-md u-btn-primary">Submit</Button>
                                <Button variant="primary" type="reset" className="gmmi-red" onClick={resetForm}>Reset</Button>
                            </>
                        }
                    </Form>
                </div>
                {showList &&
                    <span>
                        <hr className="mb-4 mt-4" />
                        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                            {
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (
                                    <div className="browser-table">
                                        {searchMsg.length > 0 &&
                                            <h5>{searchMsg}</h5>
                                        }
                                        <div className="row list-view-top">
                                            <div className="col-md-4 list-view-pagination">
                                                <SizePerPageDropdownStandalone {...paginationProps} />
                                                <PaginationTotalStandalone {...paginationProps} />
                                            </div>
                                            <div className="col-md-4">
                                                <PaginationListStandalone {...paginationProps} />
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            id="results-list"
                                            bootstrap4
                                            striped
                                            wrapperClasses="table-responsive"
                                            keyField="itnNumber"
                                            data={tableData}
                                            columns={columns}
                                            {...paginationTableProps}
                                            selectRow={selectRow}
                                            sort={{ dataField: sortField, order: sortDir }}
                                            filterPosition="top"
                                            filtersClasses="filter-wrapper"
                                            onTableChange={onTableChange}
                                            remote={ {
                                                pagination: false,
                                                filter: true,
                                                sort: true,
                                                cellEdit: false
                                              } }
                                            filter={filterFactory()}
                                        />
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>
                                )
                            }
                        </PaginationProvider>
                    </span>
                }

                {isLoading &&
                    <Col md="12" className="justify-content-center providersLoading">
                        <div className="spinner-border"></div>
                    </Col>
                }

                {showNoRusults &&
                    <Col md="12" className="justify-content-center providersLoading">
                        <b>Your search returned no results</b>
                    </Col>
                }
            </div>

            <Modal show={showModal}
                dialogClassName="modal-95w"
                onHide={ () => setShowModal(false) }
                size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        ITN# {rowToEdit?.itnNumber}, Client: {rowToEdit?.client},  Policy# {rowToEdit?.policy}, Patient: {rowToEdit?.patientName}, Billed Amount: {rowToEdit?.totalChargeFormatted}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Row>
                                <Col><h4><Form.Check type="radio" inline="true" name="modelAction" checked={ selectedAction === "A" } onChange={ (e) => setSelectedAction('A')} /> Approve</h4></Col>
                            </Row>
                            <Row>
                                <Col sm="1"></Col>
                                <Col sm="5">
                                    <b>Patient Responsiblity</b>
                                    <br />
                                    <span className='itn-subtext'>Total amount to be paid by member</span>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>$</InputGroup.Text>
                                        </InputGroup.Prepend>                                        
                                        <Form.Control
                                            type="number"
                                            value={patientResponsibilityValue}
                                            onChange={(e) => setPatientResponsibilityValue(e.target.value)}
                                            disabled={selectedAction !== "A" } /> 
                                        <InputGroup.Append>
                                            <InputGroup.Text>{ rowToEdit?.currency }</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="1"></Col>
                                <Col sm="5">
                                    <b>Maximum Covered</b>
                                    <br />
                                    <span className='itn-subtext'>Pay up to for this claim / <br/> Maximum amount covered on this claim</span>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>$</InputGroup.Text>
                                        </InputGroup.Prepend>                                        
                                        <Form.Control
                                            type="text"
                                            value={maximumCoveredValue}
                                            onChange={(e) => setMaximumCoveredValue(e.target.value)}
                                            disabled={selectedAction !== "A" } /> 
                                        <InputGroup.Append>
                                            <InputGroup.Text>{ rowToEdit?.currency }</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup> 
                                </Col>
                            </Row>
                            <Row><Col><br /><br /></Col></Row>
                            <Row>
                                <Col><h4><Form.Check type="radio" inline="true" name="modelAction" checked={ selectedAction === "D" } onChange={(e) => setSelectedAction('D')} /> Deny</h4></Col>
                            </Row>
                            <Row>
                                <Col sm="1"></Col>
                                <Col sm="5">
                                    <b>Reason for Denial</b>
                                </Col>
                                <Col>
                                    <Form.Control as="select"
                                        value={denialCodeValue}
                                        onChange={(e) => setDenialCodeValue(e.target.value)}
                                        disabled={selectedAction !== "D"}>
                                        <option value="0">Select a Denial Reason</option>
                                        {
                                            denyList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.code}>{item.description}</option>
                                                );
                                            })
                                        }
                                    </Form.Control> 
                                </Col>
                            </Row>                            
                            <Row><Col><br /><br /></Col></Row>
                            <Row>
                                <Col><h4><Form.Check type="radio" inline="true" checked={ selectedAction === "I" } name="modelAction"  onChange={(e) => setSelectedAction('I')} /> Additional Info Needed</h4></Col>
                            </Row>
                            <Row>
                                <Col sm="1"></Col>
                                <Col sm="5">
                                    <b>Info Required</b>
                                </Col>
                                <Col>
                                    <Form.Control as="select"
                                        value={additionalInfoValue}
                                        onChange={(e) => setAdditionalInfoValue(e.target.value)}
                                        disabled={selectedAction !== "I"}>
                                        <option value="0">Select Additional Information Required</option>
                                        {
                                            infoList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.code}>{item.description}</option>
                                                );
                                            })
                                        }
                                    </Form.Control> 
                                </Col>
                            </Row>
                            <Row><Col><br /><br /></Col></Row>
                            <Row>
                                <Col><h4><Form.Check type="radio" inline="true" name="modelAction" checked={ selectedAction === "O" } onChange={(e) => setSelectedAction('O')} /> Other - Special Handling</h4></Col>
                            </Row>
                            <Row>
                                <Col sm="1"></Col>
                                <Col sm="5">
                                    <b>Comment</b>
                                </Col>
                                <Col>
                                    <Form.Control as="textarea"
                                        rows={3}
                                        value={otherSpecialHandlingValue}
                                        onChange={(e) => setOtherSpecialHandlingValue(e.target.value)}
                                        disabled={selectedAction !== "O"} />
                                    { otherSpecialHandlingValue.trim().length } / 500
                                </Col>
                            </Row>
                            <Row><Col>
                                <br /> <br />
                                {showClientError &&
                                    <span className="itn-client-error">Processing Error: { clientError}</span>
                                }

                               
                            </Col></Row>
                            {isSaving &&
                                <Row>
                                    <Col className="justify-content-center">
                                        <div className="spinner-border"></div>
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col md className="pdf-col" style={{ height: '600px' }}>
                            <iframe width="100%" height="100%" src={ pdfUrl }></iframe>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md u-btn-primary" disabled={ isSaving || saveButtonDisabled } onClick={ handleSaveAndNext }>Save</Button>
                    <Button className="gmmi-red" disabled={isSaving} onClick={handleCancel}>Cancel</Button>
                    
                    <Button className="btn btn-md u-btn-primary" disabled={ isSaving } onClick={ handlePrevious }>Previous</Button>
                    <Button className="btn btn-md u-btn-primary" disabled={ isSaving } onClick={ handleNext }>Next</Button>
                </Modal.Footer>
            </Modal>
            
        </Layout>
    )
};

export default ItnAuthList;